//this script is loaded, so initialize alpinejs component 
document.querySelectorAll(".cmpTestimonialMap").forEach(element => {
    element.setAttribute("x-data", "testimonialMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d167077.47301653723!2d9.915630256082885!3d49.132506566913115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4798f1056c9284ab%3A0xfba29fb579100542!2s74564%20Crailsheim!5e0!3m2!1sde!2sde!4v1583919006027!5m2!1sde!2sde')")
})

window.testimonialMap = function(googleMapsEmbed) {
    return {
        selectedTestimonial: null,
        testimonials: [],
        regexLong: /(!2d)(.+?)(?=!)/,
        regexLat: /(!3d)(.+?)(?=!)/,
        lightbox: null,
        markerMapLayer: null,
        map: null,
        pageSize: 25,
        page: 0,
        getInitialCenterCoordinates: function() {
            if(!googleMapsEmbed) return [49.13734000000005, 10.071530000000052]; //return Crailsheim Coordinates

            return [this.regexLat.exec(googleMapsEmbed)[2], this.regexLong.exec(googleMapsEmbed)[2]]
        },
        init: function(){
            this.getTestimonials();
        },
        updateLightbox: function(selectedTestimonial){
            if(!selectedTestimonial) return;
            if(!this.lightbox) {
                this.lightbox = new SimpleLightbox('.ms-w-testimonial-map-overlay-image', { 
                    uniqueImages: false,
                    sourceAttr: "data-lightbox-src",
                });
            }else {
               this.lightbox.refresh();
            }
        },
        getTestimonials: function() {
            /*
                CHECK FOR GDPR CONSENT FIRST  
            */
            var x = __cmp('getCMPData');
            let that = this;
            if(!x)
            {
                //cmp not loaded yet
                __cmp("addEventListener",["settings", function(){
                    that.getTestimonials();
                }, false], null);
                return;
            }
            
            if(!"vendorConsents" in x || !"s4" in x.vendorConsents || !x.vendorConsents["s4"]) {
                //no consent given, listen to cmp changes
                __cmp("addEventListener",["vendorconsent", function(eventname, cmpobject, vendorid){
                    if(vendorid == "s4") that.getTestimonials();
                }, false], null);
                __cmp("addEventListener",["consentapproved", () => {that.getTestimonials()}, false], null);
                __cmp("addEventListener",["consentcustom", () => {that.getTestimonials()}, false], null);
                __cmp("addEventListener",["consent", () => {that.getTestimonials()}, false], null);

                return; //either no vendor consents set, or OSM not in vendorconsents
            }
            /*
                /CHECK FOR GDPR CONSENT FIRST  
            */

            if(this.markerMapLayer || this.map) return;

            this.markerMapLayer = L.markerClusterGroup({
                iconCreateFunction: function(cluster) {
                    //return L.divIcon({ html: cluster.getChildCount(), className: 'ms-w-testimonial-map-marker-cluster' });
                    return L.divIcon({ html: `<div class='ms-w-testimonial-map-marker-cluster'>${cluster.getChildCount()}</div>`, className: 'ms-w-testimonial-map-default-cluster-hide' });
                },
                spiderLegPolylineOptions: {
                    color: '#425368'
                }
            });

            this.markerMapLayer.on('click', function (a) {
                a.originalEvent.stopPropagation();
                that.selectedTestimonial = that.testimonials[a.layer.options.testimonialAlpineArrayIndex];
            });
            
            // initialize the map on the "map" div with a given center and zoom
            this.map = L.map('map', {
                center: this.getInitialCenterCoordinates(),
                zoom: 9,
                color: '#425368',
            });
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {foo: "bar", attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}).addTo(this.map);
                
            this.queryTestimonials();
        },
        queryTestimonials: function() {
            var that = this;

            var icon = L.icon({
                iconUrl: '/static/images/icons/location-icon.png',
                iconRetinaUrl: '/static/images/icons/location-icon-2x.png',
                
                iconSize: [32, 40],
                iconAnchor: [16, 40],
            });

            fetch("/pimcore-graphql-webservices/Gallery", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'X-API-Key': "06b6b69a83de3d86103f2c1a525263cb",
                },
                body: JSON.stringify({
                    /*query: 
                    `
                    {
                        getCustomerReferenceListing(
                            published: true
                            after: ${this.page * this.pageSize}
                            first: ${this.pageSize}
                        )
                        {
                            totalCount
                            edges {
                                node {
                                    cemetery_name
                                    reference_location {
                                        ... on Geopoint {
                                            latitude
                                            longitude
                                        }
                                    }
                                    delivery_date
                                    testimonial_thumb
                                    testimonial_full
                                    stone_model {
                                        ... on object_Gravestonevariants {
                                            testimonial_thumb
                                            frontview_full
                                            belongsToGravestone {
                                                ... on object_Gravestone {
                                                    MarketingName
                                                }
                                            }
                                            key
                                            Image {
                                                ... on asset {
                                                    fullpath
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    `*/
                    query:
                    `
                    {
                        getCustomerReferenceListing(
                        published: true
                        after: ${this.page * this.pageSize}
                        first: ${this.pageSize}
                      )
                      {
                        totalCount
                        edges {
                          node {
                            key
                            cemetery_name
                              reference_location {
                              ... on Geopoint {
                                latitude
                                longitude
                              }
                            }
                            delivery_date
                            testimonial_thumb
                            testimonial_full
                            stone_model {
                              ... on object_Grabstein {
                                testimonial_thumb
                                frontview_full
                                marketingName
                              }
                            }
                          }
                        }
                      }
                    }
                    `
                })
            })
            .then(async (response) => {
                this.page++;
                let body = await response.json();

                let markerArr = [];

                body.data.getCustomerReferenceListing.edges.forEach(customerReferenceListing => {
                    customerReferenceListing = customerReferenceListing.node;
            
                    if(!customerReferenceListing.reference_location) return;
                    
                    let index = this.testimonials.push({
                        latitude: customerReferenceListing.reference_location?.latitude ?? null,
                        longitude: customerReferenceListing.reference_location?.longitude ?? null,
                        marketingName: customerReferenceListing.stone_model?.marketingName ?? null,
                        stoneKey: customerReferenceListing.stone_model?.key ?? null,
                        graveImage: customerReferenceListing.testimonial_thumb ?? null,
                        productDetailImage: customerReferenceListing.stone_model?.testimonial_thumb ?? null,
                        cemeteryName: customerReferenceListing.cemetery_name,
                        deliveryDate: customerReferenceListing.delivery_date,
                        graveImageLarge: customerReferenceListing.testimonial_full,
                        productDetailImageLarge: customerReferenceListing.stone_model?.frontview_full ?? null,
                    });

                    markerArr.push(L.marker([customerReferenceListing.reference_location.latitude, customerReferenceListing.reference_location.longitude], {
                        icon: icon,
                        testimonialAlpineArrayIndex: index-1
                    }));

                    index++;
                })

                that.markerMapLayer.addLayers(markerArr);
                that.map.addLayer(that.markerMapLayer);
                if(this.testimonials.length < body.data.getCustomerReferenceListing.totalCount) that.queryTestimonials();
            });
        }
    }
}